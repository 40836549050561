import React from 'react';
import {formatPrice} from "../../service/funWeb";
import { useNavigate } from 'react-router-dom';
import {Progress, ProgressMeasureLocation, ProgressSize} from '@patternfly/react-core';
import quote_bg from "../../asset/img/dhk.png";

function BoxContent(item) {
    const navigate = useNavigate();
    const value = item.item;
    const handleClick = (id) => {
        navigate(`/detail/${id}`);
      };
    const now = 60;
    const left = 40;
    return (
        <div className="m-4 project-card">
            <img
                className="w-100 img-box-item-content mb-3"
                src={process.env.REACT_APP_SERVER_URL + value.image}/>
            <h3 className="project-title">{value.name}</h3>
            <div className="d-flex flex-row justify-content-between mt-3 mb-3">
                <div className="text-center">
                    <span className="percent-project">{value.profit} %</span>
                    <p>Lợi Nhuận</p>
                </div>
                <div className="text-center">
                    <span className="invest-price">{formatPrice(value.min)}</span>
                    <p>Số Tiền gửi</p>
                </div>
            </div>

            <div className="d-flex justify-content-between align-items-center">
                <span>Quy mô dự án: <b className="project-fund">{formatPrice(value.scale)}</b></span>
                <button className="btn btn-fund" onClick={()=>handleClick(value.id)}>Gửi tiền ngay</button>
            </div>

            <Progress title=" " className="mt-2" value={value.progress} measureLocation={ProgressMeasureLocation.top} size={ProgressSize.sm} />
        </div>
    );
}

export default BoxContent;