import React,{useState, useEffect} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ListDepositWithdraw from "../component/main/ListDepositWithdraw";
import APILink from "../service/APILink";
import { toast } from 'react-toastify';
function RevenueHistory(props) {
    const [data, setData] = useState([]);
    const [status, setStatus] = useState(false);

    useEffect(() => {
        callAPI();
    }, [status]); 

    const callAPI = () => {
        APILink.get(`getHistory${status === true ? "?status="+status : ""} `)
            .then(response => {
                if (response.data.status === 'success') {
                    setData(response.data.result);
                } else {
                    console.error('API error: ', response.data.message);
                }
            })
            .catch(error => {
                console.error('API call error: ', error);
            });
    };

    return (
        <Tabs
        defaultActiveKey={status === false ? "withdraw" : "deposit"}
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={(e)=>setStatus(!status)}
        >
            <Tab eventKey="withdraw" title="Hồ sơ gửi tiền">
                <ListDepositWithdraw data={data}/>
            </Tab>
            <Tab eventKey="deposit" title="Hồ sơ rút tiền">
                <ListDepositWithdraw data={data}/>
            </Tab>
        </Tabs>

    );
}

export default RevenueHistory;