import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Deposit from "../component/main/Deposit";
import Withdraw from "../component/main/Withdraw";
function DepositWithdraw(props) {
    return (
        <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            className="mb-3"
        >
            <Tab eventKey="home" title="Rút tiền">
                <Withdraw/>
            </Tab>
            <Tab eventKey="profile" title="Nạp tiền">
                <Deposit/>
            </Tab>
        </Tabs>

    );
}

export default DepositWithdraw;