import React, {useState, useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import Img from "../asset/img/user_default.jpg";
import Icon1 from "../asset/img/icon/m1.png";
import Icon2 from "../asset/img/icon/m4.png";
import Icon3 from "../asset/img/icon/m7.png";
import Icon4 from "../asset/img/icon/m8.png";
import Icon5 from "../asset/img/icon/m9.png";
import Icon6 from "../asset/img/icon/m10.png";
import Icon7 from "../asset/img/icon/m7.png";
import IconBell from "../asset/img/icon/home_message_icon.png";
import IconCalendar from "../asset/img/icon/login_in.png";
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {formatPrice} from "../service/funWeb";
function User() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const withdrawBalance = useSelector((state) => state.reducers.withdrawBalance);
    const balanceUser = useSelector((state) => state.reducers.balanceUser);
    const accumulatedBalance = useSelector((state) => state.reducers.accumulatedBalance);
    const totalBalanceInt = useSelector((state) => parseInt(state.reducers.totalBalance));
    const user = useSelector((state) => state.reducers.user);
    const logout = () => {
        Cookies.remove('token');
        toast.success('Đăng xuất thành công');
        setTimeout(() => {
            navigate('/login');
        }, 800);
    }
    const [membership, setMembership] = useState()
    // let totalBalanceInt = parseInt(totalBalance);
    useEffect(()=>{
        if (totalBalanceInt >= 4000000000) {
            setMembership("Thành viên kim cương");
        } else if (totalBalanceInt >= 2000000000) {
            setMembership("Thành viên vàng");
        } else if (totalBalanceInt >= 1000000000) {
            setMembership("Thành viên bạc");
        } else if (totalBalanceInt >= 500000000) {
            setMembership("VIP 4");
        } else if (totalBalanceInt >= 200000000) {
            setMembership("VIP 3");
        } else if (totalBalanceInt >= 100000000) {
            setMembership("VIP 2");
        } else {
            setMembership("VIP 1");
        }
    },[totalBalanceInt])

    return (
        <div className="w-100">
            <div className="w-100 infor-user-detail">
                <div className="infor-user-header w-100 h-100">
                    <div className="w-100 h-100 position-relative flex-class justify-content-between ps-4 pe-4">
                        <div className="flex-class justify-content-start">
                            <img className="img-user" src={user !== null && user.avatar !== null?process.env.REACT_APP_SERVER_URL + user.avatar : Img}/>
                            <div className="ms-2">
                                <h1>
                                 {user !== null && user.name} 
                                </h1>
                                <span>
                                    {membership}
                                </span>
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center user-top-button">
                            <img src={IconCalendar} alt=""/>
                            <img src={IconBell} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="infoBtn d-flex justify-content-around">
                    <div className="d-flex align-items-center">
                        <Link to={'/deposit-withdraw'}>Rút tiền</Link>
                    </div>
                    <div className="d-flex align-items-center">
                        <Link to={'/deposit-withdraw'}>Gửi tiền</Link>
                    </div>
                </div>
                <div className="infor-money w-90 p-5 pt-3 pb-3 g-3" style={{width: "90%"}}>
                    <div className="row w-100 h-100">
                        <div className="col-6 flex-class">
                            <div className="d-flex flex-column justify-content-start">
                                <p>Số dư tài khoản(VND)</p>
                                <span className="">{formatPrice(balanceUser)}</span>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex flex-column justify-content-end align-items-end">
                                <p>Thu nhập tích luỹ(VND)</p>
                                <span className="">{formatPrice(accumulatedBalance)}</span>
                            </div>
                        </div>
                        <div className="col-6 justify-content-center mt-3">
                            <div className="d-flex flex-column justify-content-start">
                                <p>Số tiền có thể rút(VND)</p>
                                <span className="">{formatPrice(balanceUser )}</span>
                            </div>
                        </div>
                        <div className="col-6 justify-content-center mt-3">
                            <div className="d-flex flex-column justify-content-end align-items-end">
                                <p>Đang rút tiền(VND)</p>
                                <span className="">{formatPrice(withdrawBalance)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-class justify-content-center w-100 mt-5" style={{paddingTop: "300px", marginBottom: "100px"}}>
                <div className="" style={{width: "90%"}}>
                    <ul className="list-features-user mt-5">
                        <li className="">
                        <Link to={`/historyCapital`}>
                                <div className="icon-box-item flex-class justify-content-start p-4">
                                    <img src={Icon1} className='me-2 img-icon-user-page' />
                                    <p> Khoản tiền gửi</p>
                                    <i className="fa-solid fa-chevron-right icon-chirl"></i>
                                </div>
                            </Link>
                        </li>

                        <li className="">
                            <Link to={`/user-infor`}>
                                <div className="icon-box-item flex-class justify-content-start p-4">
                                    <img src={Icon2} className='me-2 img-icon-user-page' />
                                    <p>Liên kết tài khoản</p>
                                    <i className="fa-solid fa-chevron-right icon-chirl"></i>
                                </div>
                            </Link>
                        </li>
                    </ul>
                    <ul className="list-features-user mt-5">
                        {/* <li className="">
                            <Link to={`/user-infor`}>
                                <div className="icon-box-item flex-class justify-content-start p-4">
                                    <img src={Icon3} className='me-2 img-icon-user-page' />
                                    <p> Thẻ ngân hàng của tôi</p>
                                    <i className="fa-solid fa-chevron-right icon-chirl"></i>
                                </div>
                            </Link>
                        </li> */}

                        <li className="">
                            <Link to={`/history-deposit-withdrawr`}>
                                <div className="icon-box-item flex-class justify-content-start p-4">
                                    <img src={Icon4} className='me-2 img-icon-user-page' />
                                    <p>Lịch sử gửi tiền </p>
                                    <i className="fa-solid fa-chevron-right icon-chirl"></i>
                                </div>
                            </Link>
                        </li>
                        <li className="">
                            <Link to={`/deposit-withdraw`}>
                                <div className="icon-box-item flex-class justify-content-start p-4">
                                    <img src={Icon7 } className='me-2 img-icon-user-page' />
                                    <p> Tôi muốn gửi tiền</p>
                                    <i className="fa-solid fa-chevron-right icon-chirl"></i>
                                </div>
                            </Link>
                        </li>
                        <li className="">
                            <Link to={`/deposit-withdraw`}>
                                <div className="icon-box-item flex-class justify-content-start p-4">
                                    <img src={Icon5} className='me-2 img-icon-user-page' />
                                    <p>Tôi muốn rút tiền </p>
                                    <i className="fa-solid fa-chevron-right icon-chirl"></i>
                                </div>
                            </Link>
                        </li>
                        <li className="">
                            <Link to={`/history-deposit-withdrawr`}>
                                <div className="icon-box-item flex-class justify-content-start p-4">
                                    <img src={Icon6} className='me-2 img-icon-user-page' />
                                    <p>Lịch sử rút tiền</p>
                                    <i className="fa-solid fa-chevron-right icon-chirl"></i>
                                </div>
                            </Link>
                        </li>
                    </ul>
                    <div className="flex-class justify-content-center">
                        <button className="flex-class justify-content-center btn-logout" onClick={logout}>
                            Đăng xuất
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default User;