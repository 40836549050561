import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import APILink from '../../service/APILink';
import { toast } from 'react-toastify';
function UpdatePassword() {
    const [validated, setValidated] = useState(false);

    const [passwordOld, setPasswordOld] = useState('');
  const [passwordNew, setPasswordNew] = useState('');
  const [apasswordNew, setAPasswordNew] = useState('');
  const [load, setLoad] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const changePasswordFun = (e) =>{
   e.preventDefault();
    if(apasswordNew === passwordNew){
      const data ={
        passwordOld : passwordOld,
        passwordNew : passwordNew
      }
      setLoad(true)
      APILink.post('change-password', data)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
          setPasswordOld('')
          setPasswordNew('');
          setAPasswordNew('');
        }else{
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
    else{
      toast.error("xác nhận mật khẩu mới và mật khẩu mới phải trùng khớp");
    }
    setLoad(false)
  }

    return (
        <div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} md="12" className="form-input-custorms mt-3 mb-3">
                        <Form.Label>Mật khẩu cũ</Form.Label>
                        <Form.Control
                               required
                               type="password"
                               onChange={(e)=>setPasswordOld(e.target.value)}
                        />
                        <Form.Control.Feedback></Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="form-input-custorms mt-3 mb-3">
                        <Form.Label> Mật khẩu mới </Form.Label>
                        <Form.Control
                            required
                    type="password"
                    onChange={(e)=>setPasswordNew(e.target.value)}
                        />
                        <Form.Control.Feedback></Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="form-input-custorms mt-3 mb-3">
                        <Form.Label>Xác nhận mật khẩu mới </Form.Label>
                        <Form.Control
                            required
                            type="password"
                            onChange={(e)=>setAPasswordNew(e.target.value)}
                        />
                        <Form.Control.Feedback></Form.Control.Feedback>
                    </Form.Group>

                </Row>
                <Button type="submit" onClick={(e)=>changePasswordFun(e)} disabled={load}> {load === true ? 'Đang xử lý...' : 'Đổi mật khẩu'}</Button>
            </Form>
        </div>
    );
}

export default UpdatePassword;