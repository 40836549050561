import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import EditUser from "../component/main/EditUser";
import UpdatePassword from "../component/main/UpdatePassword";
function UserInfor(props) {
    return (
        <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            className="mb-3"
        >
            <Tab eventKey="home" title="Xác thực thông tin">
                <EditUser/>
            </Tab>
            <Tab eventKey="profile" title="Đổi mật khâu">
                <UpdatePassword/>
            </Tab>
        </Tabs>

    );
}

export default UserInfor;