import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import APILink from '../../service/APILink';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Deposit() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.reducers.user);
    const [originalBankNumber, setOriginalBankNumber] = useState('');
    const [status, setStatus] = useState(false);
    const maskBankNumber = (bank_number) => {

        if (!bank_number) return '';
        const visibleDigits = bank_number.slice(0, -5);
        const maskedDigits = '*****';
        return visibleDigits + maskedDigits;
    };
    useEffect(() => {
        if (user) {
            setOriginalBankNumber(user.bank_number || '');
            setInitialValues({
                amount: '',
                bank_name: user.bank_name || '',
                bank_number: status ? user.bank_number : maskBankNumber(user.bank_number) || '',
                username: user.full_name || ""
            });
        }
    }, [user, status])
    const [initialValues, setInitialValues] = useState({
        amount: '',
        bank_name: '',
        bank_number: '',
        username: ''
    });
    useEffect(() => {
        if (user) {
            setOriginalBankNumber(user.bank_number || '');
            setInitialValues({
                amount: '',
                bank_name: user.bank_name || '',
                bank_number: status ? user.bank_number : maskBankNumber(user.bank_number) || '',
                username: user.full_name || ""
            });
            setOriginalBankNumber( user.bank_number  || "")
        }
    }, [user]);

    const schema = yup.object().shape({
        amount: yup.number().required('Số tiền là bắt buộc'),
    });

    const postWithdraw = async (values, resetForm) => {
        setLoading(true);
        if (
            initialValues.bank_name.trim().length === 0 &&
            initialValues.bank_number.trim().length === 0 &&
            initialValues.username.trim().length === 0
        ) {
            toast.error('Bạn cần xác thực thông tin trước khi thực hiện hành động này');
            navigate('/user-infor');
            return;
        }
        const updatedValues = { ...values, bank_number: originalBankNumber };
        
        try {
            const response = await APILink.post('withdraw', updatedValues);
            if (response.data.status === 'success') {
                toast.success(response.data.message);
                resetForm();
                setTimeout(() => {
                    window.location.href = '/';
                }, 1500);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Có lỗi xảy ra, vui lòng thử lại.');
        } finally {
            setLoading(false);
        }
    };
    return (
        <Formik
            validationSchema={schema}
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, { resetForm }) => postWithdraw(values, resetForm)}
        >
            {({ handleSubmit, handleChange, values, touched, errors, resetForm }) => (
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" className="form-input-customs mt-3 mb-3">
                            <Form.Label>Số tiền cần rút</Form.Label>
                            <Form.Control
                                required
                                name="amount"
                                value={values.amount}
                                type="number"
                                isValid={touched.amount && !errors.amount}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                <ErrorMessage name="amount" />
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="12" className="form-input-customs mt-3 mb-3">
                            <Form.Label>Tên ngân hàng</Form.Label>
                            <Form.Control
                                value={values.bank_name}
                                disabled
                            />
                            <Form.Control.Feedback type="invalid">
                                <ErrorMessage name="bank_name" />
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="12" className="form-input-customs mt-3 mb-3">
                            <Form.Label>Tài khoản ngân hàng</Form.Label>
                            <div className='bank_number-input position-relative w-100'>
                                <Form.Control
                                    className='w-100 h-100'
                                    value={values.bank_number}
                                    disabled
                                />
                                <i 
                                    className={`fa ${status ? "fa-eye-slash" : "fa-eye"} icon-eye`} 
                                    onClick={() => setStatus(!status)}
                                ></i>
                            </div>
                            <Form.Control.Feedback type="invalid">
                                <ErrorMessage name="bank_number" />
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="12" className="form-input-customs mt-3 mb-3">
                            <Form.Label>Tên tài khoản</Form.Label>
                            <Form.Control
                                name="username"
                                value={values.username}
                                disabled
                            />
                            <Form.Control.Feedback type="invalid">
                                <ErrorMessage name="username" />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Button type="submit" disabled={loading}>
                        {loading ? 'Loading...' : 'Rút'}
                    </Button>
                </Form>
            )}
        </Formik>
    );
}

export default Deposit;
