import React, { useState } from 'react';
import Logo from "../../asset/img/logo.png";
import {setModalUser, setSliderBar} from "../../redux/accction/acction";
    import {Link, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

function Header() {
    const dispatch = useDispatch();
    const isLogin = useSelector((state) => state.reducers.isLogin);
    return (
        <header className='flex-class justify-content-center align-items-center w-100 h-100 bg-white with-center'>
            <div className="d-flex justify-content-center w-100">
                <img src={Logo} className="logo"/>
            </div>
            {isLogin === false && (
                <div className="position-relative h-100">
                <div className="auth h-100">
                    <div className="d-flex links gap-2 h-100 align-items-center">
                        <Link to={'/login'}>Đăng ký</Link>
                        <Link to={'/login'}>Đăng nhập</Link>
                    </div>
                </div>
            </div>
            )}
            
        </header>
    )
}

export default Header
