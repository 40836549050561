import React, { useState, useEffect } from 'react';
import APILink from '../service/APILink';

function Review() {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await APILink.get('about');
                if (response.data.status === "success") {
                    setData(response.data.result);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="mt-5" dangerouslySetInnerHTML={{ __html: data || '' }} />
    );
}

export default Review;
