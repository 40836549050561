import React from 'react';
import Table from 'react-bootstrap/Table';
import {formatDate, formatPrice} from "../../service/funWeb";
function ListDepositWithdraw({ data }) {
    return (
        <div className="w-100">
            <Table className="w-100">
                <thead>
                    <tr>
                        <th>Ngày giao dịch</th>
                        <th>Phương thức </th>
                        <th>Số tiền</th>
                        <th>Trạng thái</th>
                    </tr>
                </thead>
                <tbody>
                    {data && data.length > 0 && data.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                   {formatDate(item.created_at)}

                                </td>
                                <td>{item.type === "bank" && "Ngân hàng"}</td>
                                <td>{formatPrice(item.amount)}</td>
                                <td>
                                    {item.is_approve === 0 && "Chờ xử lý"}
                                    {item.is_approve === 1 && "Đã duyệt"}
                                    {item.is_approve === 2 && "Từ chối"}
                                </td>
                            </tr>
                        )
                    })}


                </tbody>
            </Table>
        </div>
    );
}

export default ListDepositWithdraw;