import React, { useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import APILink from "../../service/APILink";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Deposit(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [load, setLoad] = useState(false);
    const user = useSelector((state) => state.reducers.user);
    const [originalBankNumber, setOriginalBankNumber] = useState('');
    const [status, setStatus] = useState(false);
    const [infor, setInfor] = useState(null);
    const [iamge,  setImage] = useState(null);
    const onchangeImage = (event)=>{
        setImage(event.target.files[0]);
    }
    const [initialValues, setInitialValues] = useState({
        amount: "",
        bank_name: "",
        bank_number: "",
        username: ""
    });
    const maskBankNumber = (bank_number) => {
        
        if (!bank_number) return '';
        const visibleDigits = bank_number.slice(0, -5);
        const maskedDigits = '*****';
        return visibleDigits + maskedDigits;
    };
    useEffect(() => {
        if (user) {
            setOriginalBankNumber(user.bank_number || '');
            setInitialValues({
                amount: '',
                bank_name: user.bank_name || '',
                bank_number: status ? user.bank_number : maskBankNumber(user.bank_number) || '',
                username: user.full_name || ""
            });
        }
    }, [user, status])
   
    const schema = yup.object().shape({
        amount: yup.number().required('Số tiền là bắt buộc'),
        bank_name: yup.string().required('Tên ngân hàng là bắt buộc'),
        bank_number: yup.string().required('Số tài khoản ngân hàng là bắt buộc'),
        username: yup.string().required('Tên tài khoản là bắt buộc'),
    });
    useEffect(()=>{
        ownerInformation();
    },[])
    const ownerInformation = () => {
        APILink.get('owner-information')
          .then((response) => {
            if (response.data.status === "success") {
               setInfor(response.data.result)
            }
          })
          .catch((error) => {
            console.error(error);
          });
      };
    const postDeposit = async (e, values, resetForm) => {
        e.preventDefault();
        if (
            initialValues.bank_name.trim().length === 0 &&
            initialValues.bank_number.trim().length === 0 &&
            initialValues.username.trim().length === 0
        ) {
            navigate('/user-infor');
            toast.error('Bạn cần xác thực thông tin trước khi thực hiện hành động này');
            return;
        }
        const formData = new FormData();
        // formData.append('image', iamge);
        Object.keys(values).forEach(key => {
            formData.append(key, values[key]);
        });
        setLoad(true);
        try {
            const response = await APILink.post('recharge' , formData,{
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              });
            if (response.data.status === 'success') {
                toast.success(response.data.message);
                resetForm();
                //set timeout 1500 then redirect to /
                setTimeout(() => {
                    window.location.href = '/';
                }, 1500);
            }
            else{
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoad(false);
        }
    };
    
    

    return (
        <div>
            <Formik
               validationSchema={schema}
               initialValues={initialValues}
               enableReinitialize
             
            >
                {({ handleSubmit, handleChange, values, touched, errors, resetForm }) => (
                    <Form>
                             <div dangerouslySetInnerHTML={{ __html: infor }} />
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" className="form-input-customs mt-3 mb-3">
                                <Form.Label>Số tiền cần nạp</Form.Label>
                                <Form.Control
                                    required
                                    name="amount"
                                    value={values.amount}
                                    type="number"
                                    isValid={touched.amount && !errors.amount}
                                    onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <ErrorMessage name="amount" />
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="form-input-customs mt-3 mb-3">
                                <Form.Label>Tên ngân hàng</Form.Label>
                                <Form.Control
                                    required
                                  
                                    value={values.bank_name}
                                    disabled
                                />
                               
                                <Form.Control.Feedback type="invalid">
                                    <ErrorMessage name="bank_name" />
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="form-input-customs mt-3 mb-3">
                                    <Form.Label>Tài khoản ngân hàng</Form.Label>
                                    <div className='bank_number-input position-relative w-100'>
                                        <Form.Control
                                            className='w-100 h-100'
                                            value={values.bank_number}
                                            disabled
                                        />
                                        <i 
                                            className={`fa ${status ? "fa-eye-slash" : "fa-eye"} icon-eye`} 
                                            onClick={() => setStatus(!status)}
                                        ></i>
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        <ErrorMessage name="bank_number" />
                                    </Form.Control.Feedback>
                                </Form.Group>
                            <Form.Group as={Col} md="12" className="form-input-customs mt-3 mb-3">
                                <Form.Label>Tên tài khoản</Form.Label>
                                <Form.Control
                                    required
                                 disabled
                                    value={values.username}
                                  
                                />
                                <Form.Control.Feedback type="invalid">
                                    <ErrorMessage name="username" />
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/*<Form.Group as={Col} md="12" className="form-input-customs mt-3 mb-3">*/}
                            {/*    <Form.Label>Ảnh chuyển khoản</Form.Label><br/>*/}
                            {/*   */}
                            {/*    <input  */}
                            {/*      // required*/}
                            {/*        name="file"*/}
                            {/*        type="file"*/}
                            {/*         accept=".jpg, .jpeg, .png, .pdf"*/}
                            {/*         onChange={(e)=>onchangeImage(e)}/>*/}
                            {/*     */}
                            {/*  */}
                            {/*    <Form.Control.Feedback type="invalid">*/}
                            {/*        <ErrorMessage name="username" />*/}
                            {/*    </Form.Control.Feedback>*/}
                            {/*</Form.Group>*/}
                        </Row>
                        <Button type="submit"  onClick={(e) => postDeposit(e, values, resetForm)} disabled={load}>
                            {load ? 'Loading...' : 'Nạp'}
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default Deposit;
