import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./component/header/header";
import Main from "./page/main";
import NavBarBottom from "./component/navbar/navbarBottom"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './asset/css/app.css';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { setUser, setBalanceUser, setWithdrawBalance, setAccumulatedBalance,setTotalBalance } from "./redux/accction/acction";
import React, { useState, useEffect } from 'react';
import APILink from './service/APILink';
import { useLocation } from 'react-router-dom';
function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathAfterDomain = location.pathname;
  const token = Cookies.get('token');

  useEffect(() => {
    if (token) {
      getDatainforUser();
    }
  }, [token]);

  const getDatainforUser = () => {
    APILink.get('user')
      .then((response) => {
        if (response.data.status === "success") {
          dispatch(setUser(response.data.user));
          dispatch(setBalanceUser(response.data.balance));
          dispatch(setWithdrawBalance(response.data.withdrawBalance));
          dispatch(setAccumulatedBalance(response.data.accumulatedBalance));
          dispatch(setTotalBalance(response.data.totalBalance));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className={` ${pathAfterDomain !== '/chat' ?"container" : "container-fluid"}  flex-class justify-content-center`} style={{ height: "100vh" }}>
      <ToastContainer />
      <div className={`${pathAfterDomain !== '/chat' ?"container-pay main-container" : "w-100"}  h-100 `}>
        {pathAfterDomain !== '/chat' ?
          (
            <>
              <div className="box-header flex-class justify-content-center">
                <Header />
              </div>
              <main className='w-100 h-100 p-3'>
                <Main />
              </main>
              <div className="box-navbar-bottom flex-class justify-content-center">
                <NavBarBottom />
              </div>
            </>

          ) :
          (
            <Main />
          )
        }
      </div>


    </div>
  );
}

export default App;
