import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import icon_deposit from '../../asset/img/icon/navinvest.svg';
import icon_chat from '../../asset/img/icon/navborrow.svg';
import icon_profile from '../../asset/img/icon/navacc.svg';
import icon_home from '../../asset/img/icon/navHomeH.svg';
function NavbarBottom() {
    const [value, setValue] = useState('home');
    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue !== 'chat') {
            switch (newValue) {
                case 'home':
                    navigate('/');
                    break;
                case 'invest':
                    navigate('/invest');
                    break;
                case 'user':
                    navigate('/user');
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <Box sx={{ width: 500 }} className='h-100 w-100 with-center'>
            <BottomNavigation
                showLabels
                className='h-100'
                value={value}
                onChange={handleChange}
            >
                <BottomNavigationAction label="Trang chủ" value="home" icon={
                    <div className="footer-icon" style={{
                        width: '35px',
                        height: '35px',
                        backgroundImage: `url(${icon_home})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }} />
                } />
                <BottomNavigationAction label="Dự án" value="invest" icon={
                    <div className="footer-icon" style={{
                        width: '35px',
                        height: '35px',
                        backgroundImage: `url(${icon_deposit})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }} />
                } />
               <BottomNavigationAction 
                        label="CSKH"
                        value="chat" 
                        icon={
                            <div className="footer-icon" style={{
                                width: '35px',
                                height: '35px',
                                backgroundImage: `url(${icon_chat})`,
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat'
                            }} />
                        }
                        onClick={() => {
                            const newUrl = `${window.location.origin}/chat`;
                            window.open(newUrl, '_blank');
                        }}
                    />

                <BottomNavigationAction label="Của tôi" value="user" icon={
                    <div className="footer-icon" style={{
                        width: '35px',
                        height: '35px',
                        backgroundImage: `url(${icon_profile})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }} />
                } />
            </BottomNavigation>
        </Box>
    );
}

export default NavbarBottom;
