import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ListDepositWithdraw from "../component/main/ListDepositWithdraw";
function RevenueHistory(props) {
    return (
        <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            className="mb-3"
        >
            <Tab eventKey="home" title="Hồ sơ rút tiền">
                <ListDepositWithdraw/>
            </Tab>
            <Tab eventKey="profile" title="Hồ sơ gửi tiền">
                <ListDepositWithdraw/>
            </Tab>
        </Tabs>

    );
}

export default RevenueHistory;