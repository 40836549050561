import React, {useState, useEffect} from 'react'
import BannerVideo from "../component/main/VideoBaner";
import TabListLayer from "../component/main/TabList_layer";
import BoxContentHome  from "../component/main/boxContentHome";
import megaphone_img from "../asset/img/icon23.png";
import APILink from "../service/APILink";
import {toast} from "react-toastify";

function Home() {
    const [data, setData] = React.useState(null);
    useEffect(() => {
        callAPIFun();
    }, []);
    const callAPIFun = () => {
        APILink.get('slogan')
            .then((response) => {
                if (response.data.status === "success") {
                    setData(response.data.result);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error('Failed to load slogan'); // Thêm thông báo lỗi nếu cần
            });
    };

    return (
        <div className='h-100 mt-5'>
            <div className="bg-white pb-3">
                <BannerVideo/>
                <TabListLayer/>
                <div className="d-flex align-items-center p-2">
                    <img src={megaphone_img} alt="" height="50px" width="50px"/>
                    <marquee>
                        <strong>{data}</strong>
                    </marquee>
                </div>
            </div>
            <BoxContentHome/>
        </div>
    )
}

export default Home