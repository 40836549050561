import React, { useState, useEffect } from 'react';
import APILink from '../service/APILink';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { formatPrice } from "../service/funWeb";
import { useSelector, useDispatch } from 'react-redux';
function Detail() {
        const { id } = useParams();
        const [data, setData] = useState(null);
        const [message, setmessage] = useState(null);
        const [amont, setAmont] = useState(null);
        const [load, setLoad] = useState(false);
        const balanceUser = useSelector((state) => state.reducers.balanceUser);
        useEffect(() => {
                callGetListChat()
        }, [id])
        // useEffect(()=>{
        //         calsetting()
        // },[])
        const callGetListChat = () => {
                APILink.get(`project-detail/${id}`)
                        .then((response) => {
                                if (response.data.status === 'success') {
                                        setData(response.data.result);
                                }
                        })
                        .catch((error) => {
                                console.error(error);
                        });
        };
        // const calsetting = () => {
        //
        //     APILink.get(`setting`)
        //         .then((response) => {
        //             if (response.data.status === 'success') {
        //                 setmessage(response.data.message);
        //             }
        //         })
        //         .catch((error) => {
        //             console.error(error);
        //         });
        // };
        const sendMoney = async () => {
                setLoad(true)
                if (balanceUser < amont) {
                        toast.error('Số tiền không đủ vui lòng nạp thêm tiền !');
                        setLoad(false);
                        return;
                }
                if (!data || !data.id) {
                        toast.error('Vui lòng thử lại');
                        setLoad(false);
                        return;
                }
                if(amont < data.min){
                        toast.error('Vui lòng nhập số tiền lớn hơn số tiền tối thiếu');
                        setLoad(false);
                        return;
                }
                const value = {
                        amount: amont,
                        projectId: data.id
                }
                APILink.post('invest', value)
                .then((response) => {
                    if (response.data.status === 'success') {
                        toast.success(response.data.message);
                        setTimeout(() => {
                            window.location.href = '/';
                        }, 1500);
                    }
                    else {
                        toast.error(response.data.message);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setLoad(false);
                });
        }
        return (
                <div>
                        <p className='text-center fs-5 mt-4 mb-4'>
                                Gọi vốn
                        </p>
                        <div className='bg-red-primary w-100 p-3 flex-class justify-content-start color-white'>
                                <p className='fs-6 mt-3 mb-2'>Số dư ngân hàng :  <b>{formatPrice(balanceUser)}</b> </p>
                        </div>
                        <div className='mt-3 mb-3 pe-3 ps-3 flex-class justify-content-between'>
                                <span>Số tiền tối thiểu</span>
                                <p>{data && data !== null && formatPrice(data.min)}</p>
                        </div>
                        <hr />
                        <div className='mt-3 mb-3 pe-3 ps-3 flex-class justify-content-between'>
                                <span>Số tiền tối đa</span>
                                <p>{data && data !== null && formatPrice(data.max)}</p>
                        </div>
                        <hr />
                        <div className='mt-3 mb-3 pe-3 ps-3 flex-class justify-content-between'>
                                <span>Thời hạn </span>
                                <p>Trả lãi tự động sau:
                                    <span className="fw-bold">
                                        {data && data !== null && data.time_day} phút
                                    </span>
                                </p>
                        </div>
                        <hr />
                        <div className='mt-3 mb-3 pe-3 ps-3 flex-class justify-content-between'>
                                <span>Thu nhập</span>
                                <p>Mỗi ngày tính lãi {data && data !== null && data.profit}% <br />Hoàn trả gốc khi đáo hạn</p>
                        </div>
                        <hr />
                        <div className='mt-3 mb-3 pe-3 ps-3 flex-class justify-content-between'>
                                <span>Số tiền gửi</span>   <input type='number' value={amont} onChange={(e) => setAmont(Number(e.target.value))} placeholder='Nhập số tiền cần gửi' className='input-enter-money bg-transparent  text-right;' />
                        </div>

                        <hr />
                        <div className='mt-3 mb-3 pe-3 ps-3 flex-class justify-content-between'>
                                <span>Số tiền lãi bạn nhận được là </span>
                                <p>{data && data !== null && amont !== null && formatPrice(amont / 100 * data.profit)}</p>
                        </div>

                        <hr />
                        <div className='w-100 mt-5'>
                                <button disabled={load} className='btn-send-money w-100 pt-4 pb-4' onClick={sendMoney}>Gửi tiền ngay</button>
                        </div>
                </div>
        )
}

export default Detail