import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import { formatPrice, formatDate } from "../../service/funWeb";
function TableComponent({ data }) {
    const getStatusText = (endsAt) => {
        const isExpired = moment(endsAt).isBefore(moment());
        return isExpired ? 'Đã đáo hạn' : 'Chưa đáo hạn';
    };
    return (
        <div>
            <div className="list-data pt-4 pb-4 ps-2 pe-2">
                {data.length > 0 ? (
                    <Accordion defaultActiveKey="0">
                        {data.map((item, index) => (
                            <Accordion.Item eventKey={index} key={index}>
                                <Accordion.Header>
                                    <div className="p-3 mt-3 mb-3">

                                        <p>{item.project.name}</p>
                                        <p className='mt-3 mb-3 color-red font-weight'>{formatPrice(item.amount)}</p>

                                        <p>{getStatusText(item.ends_at)}</p>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>Số tiền đầu tư :{formatPrice(item.amount)}</p>
                                    <p>Thời gian đầu tư {formatDate(item.created_at)}</p>
                                    <p>Thời gian hết hạn :{formatDate(item.ends_at)}</p>
                                    <p>Trạng thái :{getStatusText(item.ends_at)}</p>
                                    <p>Tên dự án :{item.project.name}</p>
                                    <p>Số phần trăm lãi :{item.project.profit} %</p>
                                    <p>Tổng tiền nhận được :{formatPrice(item.interest)} </p>
                                    <p>Tiền lãi đến hiện tại :{formatPrice(item.current_interest)} </p>      
                                    <p>Tổng tiền lãi :{formatPrice(item.interest-item.amount)} </p>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                ) : (
                    <p>Không có dữ liệu</p>
                )}
            </div>
        </div>
    );
}

export default TableComponent;
