import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Formik, Field, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import APILink from "../../service/APILink";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

function EditUser() {
    const navigate = useNavigate();
    const [load, setLoad] = useState(false);
    const [imgBack, setImgBack] = useState(null);
    const [imageFront, setImageFront] = useState(null);
    const user = useSelector((state) => state.reducers.user);
    const [initialValues, setInitialValues] = useState({
        fullname: '',
        phone: '',
        // identification: '',
        email: '',
        bank_name: '',
        bank_number: '',
    });

    useEffect(() => {
        if (user !== null) {
            setInitialValues({
                fullname: user.full_name,
                phone: user.phone,
                // identification: user.identification,
                email: user.email,
                bank_name: user.bank_name,
                bank_number:user.bank_number,
                bank_department: user.bank_department,
            });
        }
    }, [user]);

    const schema = yup.object().shape({
        fullname: yup.string().required('Tên thật là bắt buộc'),
        phone: yup.string().required('Số điện thoại là bắt buộc'),
        email: yup.string().required('Username không hợp lệ').required('Username là bắt buộc'),
        // identification: yup.string().required('Số căn cước/CMND là bắt buộc'),
        bank_name: yup.string().required('Tên ngân hàng là bắt buộc'),
        bank_number: yup.string().required('Tài khoản là bắt buộc'),
        bank_department: yup.string().required('Chi nhánh ngân hàng là bắt buộc'),
    });

    const onchangeImageBack = (event) => {
        setImgBack(event.target.files[0]);
    };

    const onchangeImageFront = (event) => {
        setImageFront(event.target.files[0]);
    };

    const LoginFunn = async (values, { resetForm }) => {
        setLoad(true);
        const formData = new FormData();
        formData.append('backImg', imgBack);
        formData.append('frontImg', imageFront);
        Object.keys(values).forEach(key => {
            formData.append(key, values[key]);
        });
        try {
            const response = await APILink.post('veryfication-user', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.status === 'success') {
                toast.success(response.data.message);
                resetForm();
                // Optionally navigate to another page
                //wait 1.5s to redirect to /
                setTimeout(() => {
                    window.location.href = '/';
                }, 1500);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoad(false);
        }
    };

    return (
        <div>
      <Formik
                validationSchema={schema}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={LoginFunn}  
            >
                {({ handleSubmit, handleChange, values, touched, errors, resetForm }) => (
                    <FormikForm className='login w-95 p-4'>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" className="form-input-custom mt-3 mb-3">
                                <Form.Label>Username</Form.Label>
                                <Field
                                    disabled
                                    name="email"
                                    as={Form.Control}
                                    type="text"
                                    isInvalid={touched.email && !!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="form-input-custom mt-3 mb-3">
                                <Form.Label>Tên thật</Form.Label>
                                <Field
                                    disabled={user && user.full_name !== null}
                                    name="fullname"
                                    as={Form.Control}
                                    type="text"
                                    isInvalid={touched.fullname && !!errors.fullname}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.fullname}
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/*<Form.Group as={Col} md="12" className="form-input-custom mt-3 mb-3">*/}
                            {/*    <Form.Label>Số căn cước/CMND</Form.Label>*/}
                            {/*    <Field*/}
                            {/*        name="identification"*/}
                            {/*        as={Form.Control}*/}
                            {/*        type="text"*/}
                            {/*        isInvalid={touched.identification && !!errors.identification}*/}
                            {/*    />*/}
                            {/*    <Form.Control.Feedback type="invalid">*/}
                            {/*        {errors.identification}*/}
                            {/*    </Form.Control.Feedback>*/}
                            {/*</Form.Group>*/}
                            <Form.Group as={Col} md="12" className="form-input-custom mt-3 mb-3">
                                <Form.Label>Tên ngân hàng</Form.Label>
                                <Field
                                    disabled={user && user.full_name !== null}
                                    name="bank_name"
                                    as={Form.Control}
                                    type="text"
                                    isInvalid={touched.bank_name && !!errors.bank_name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.bank_name}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="form-input-custom mt-3 mb-3">
                                <Form.Label>Chi nhánh ngân hàng</Form.Label>
                                <Field
                                    disabled={user && user.full_name !== null}
                                    name="bank_department"
                                    as={Form.Control}
                                    type="text"
                                    isInvalid={touched.bank_department && !!errors.bank_department}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.bank_department}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="form-input-custom mt-3 mb-3">
                                <Form.Label>Tài khoản ngân hàng</Form.Label>
                                <Field
                                    disabled={user && user.full_name !== null}
                                    name="bank_number"
                                    as={Form.Control}
                                    type="text"
                                    isInvalid={touched.bank_number && !!errors.bank_number}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.bank_number}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="form-input-custom mt-3 mb-3">
                                <Form.Label>Số điện thoại</Form.Label>
                                <Field
                                    disabled={user && user.full_name !== null}
                                    name="phone"
                                    as={Form.Control}
                                    type="text"
                                    isInvalid={touched.phone && !!errors.phone}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.phone}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="form-input-custom mt-3 mb-3">
                                <Form.Label>Ảnh căn cước mặt trước</Form.Label>
                                <Form.Control
                                    disabled={user && user.full_name !== null}
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf"
                                    onChange={onchangeImageBack}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="form-input-custom mt-3 mb-3">
                                <Form.Label>Ảnh căn cước mặt sau</Form.Label>
                                <Form.Control
                                    disabled={user && user.full_name !== null}
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf"
                                    onChange={onchangeImageFront}
                                />
                            </Form.Group>
                        </Row>
                        <Button  type="submit" disabled={load}>
                            Gửi đi
                        </Button>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
}

export default EditUser;
