import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import APILink from '../../service/APILink';
import { toast } from 'react-toastify';
import ImageLoader from "../../asset/img/retina-image-lazy-loader.jpg";
function VideoBaner(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        callAPIFun();
    }, []);

    const callAPIFun = () => {
        APILink.get('banners')
            .then((response) => {
                if (response.data.status === "success") {
                    setData(response.data.result);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error('Failed to load banners');
            });
    };

    return (
        <div className="w-100">
            <Swiper
                navigation={true}
                pagination={{ clickable: true }}
                autoplay={{
                    delay: 5000, // Thay đổi thời gian chuyển slide (5000ms = 5s)
                    disableOnInteraction: false, // Tự động chạy ngay cả khi người dùng tương tác
                }}
                modules={[Navigation, Pagination, Autoplay]}
                className="w-100 slider-img"
            >
                {data && data.length > 0 ? (
                    data.map((item, index) => (
                        <SwiperSlide key={index} className='w-100'>
                            <img
                                className="w-100 h-100"
                                src={process.env.REACT_APP_SERVER_URL + item.image}
                                alt={`Banner ${index}`}
                            />
                        </SwiperSlide>
                    ))
                ) : (
                    <SwiperSlide className='w-100'>
                       <img src={ImageLoader}     className="w-100 h-100"/>
                    </SwiperSlide>
                )}
            </Swiper>
        </div>
    );
}

export default VideoBaner;
