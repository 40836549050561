import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ element, redirectPath }) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const token = Cookies.get('token');
  const [tokenFromUrl, setTokenFromUrl] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenUrl = urlParams.get('token');

    if (tokenUrl) {
      Cookies.set('token', tokenUrl, { expires: 30 });
      setTokenFromUrl(tokenUrl);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return null; // You might want to show a loading spinner or similar
  }

  // Check if there is a token or tokenFromUrl and navigate accordingly
  if (token || tokenFromUrl) {
    if (location.pathname === '/login') {
      return <Navigate to={redirectPath || '/user'} />;
    }
    return element;
  }

  // If there is no token and the current path is not '/login', redirect to login
  if (!token && location.pathname !== '/login') {
    return <Navigate to="/login" />;
  }

  return element;
};

export default ProtectedRoute;
