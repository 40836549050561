import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import React, { useState, useEffect } from 'react';
import APILink from "../service/APILink";
import { setIsLogin } from '../redux/accction/acction';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
function ForgotPassword() {
  const { Formik } = formik;
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().required(),
    phone: yup.string().required(),
  });
  const LoginFunn = async (e, values, resetForm) => {
    e.preventDefault();
    setLoad(true);
    try {
      const response = await APILink.post("forgot-password", values);
      if (response.data.status === 'success') {
        toast.success(`Gửi thông tin thành công !`);
        resetForm();

      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <div className='w-100 flex-class justify-content-center position-relative login-page'>
      <div className='overlay w-100 h-100'> </div>
      <Formik
        validationSchema={schema}
        initialValues={{
          name: '',
          email: '',
          phone: ""
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors, resetForm }) => (
          <Form className='login w-95 p-4'>
            <div className='login-title flex-class justify-content-center mt-3 mb-3'>
              <h3 className={`titel-span-text color-white fs-5 `}>Quên mật khẩu</h3>
            </div>
            <Row className="mb-3">
              <Form.Group
                as={Col}
                md="12"
                className='mt-3 mb-3'

              >
                <Form.Label className='color-white'>Tên đăng nhập (Số điện thoại hoặc Gmail)</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  className='form-input pt-2 pb-2 bg-transparent color-white '
                  value={values.name}
                  onChange={handleChange}
                  isValid={touched.name && !errors.name}
                />
                <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                md="12"
                className='mt-3 mb-3'
              >
                <Form.Label className='color-white'>Nhập email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  className='form-input pt-2 pb-2 bg-transparent color-white '
                  value={values.email}
                  onChange={handleChange}
                  isValid={touched.email && !errors.email}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                md="12"
                className='mt-3 mb-3'
              >
                <Form.Label className='color-white'>Nhập số điện thoại</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  className='form-input pt-2 pb-2 bg-transparent color-white '
                  value={values.phone}
                  onChange={handleChange}
                  isValid={touched.phone && !errors.phone}
                />
              </Form.Group>
            </Row>

            <Button
              type="submit"
              disabled={load}
              className='btn-login bg-transparent w-100 pt-3 pb-3'
              onClick={(e) => LoginFunn(e, values, resetForm)} >
             Gửi thông tin
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ForgotPassword;