import React, { useState, useEffect } from 'react';
import ListHistory from "../component/main/listHistory";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import APILink from "../service/APILink";
import { toast } from 'react-toastify';

function HistoryCapital(props) {
    const [data, setData] = useState([]);
    const [status, setStatus] = useState(false);

    useEffect(() => {
        callAPI();
    }, [status]); // Call API whenever status changes

    const callAPI = () => {
        APILink.get(`invest${status === true ? "?status="+status : ""} `)
            .then(response => {
                if (response.data.status === 'success') {
                    setData(response.data.result);
                } else {
                    console.error('API error: ', response.data.message);
                }
            })
            .catch(error => {
                console.error('API call error: ', error);
            });
    };

    console.log(status)
    return (
        <div>
            <Tabs
                defaultActiveKey={status === false ? "satisfied" : "progress"}
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={(e)=>setStatus(!status)}
            >
                <Tab eventKey="progress" title="Đang góp vốn">
                    <ListHistory data={data} />
                </Tab>
                <Tab eventKey="satisfied" title="Đã Kết thúc">
                    <ListHistory data={data} />
                </Tab>
            </Tabs>
        </div>
    );
}

export default HistoryCapital;
