import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './home';
import Invest from './invest';
import Chat from './chatRealTime';
import User from './User';
import HistoryCapital from './HistoryCapital';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import RevenueHistory from './RevenueHistory';
import UserInfor from './userInfor';
import DepositWithdraw from './DepositWithdraw';
import HistoryDepositWithdraw from './HistoryDepositWithdraw';
import Detail from './Detail';
import Login from "./login";
import Review from "./review";
import ForgotPassword from "./forgotPassword";
function Main() {
  return (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/invest" element={<Invest />} />
        <Route path="/review" element={<Review />} />
        <Route path="/message" element={<ProtectedRoute element={<Chat />} />} />
        <Route path="/chat" element={<ProtectedRoute element={<Chat />} />} />
        <Route path="/user" element={<ProtectedRoute element={<User />} />} />
        <Route path="/revenue-history" element={<ProtectedRoute element={<RevenueHistory />} />} />
        <Route path="/equity" element={<ProtectedRoute element={<RevenueHistory />} />} />
        <Route path="/user-infor" element={<ProtectedRoute element={<UserInfor />} />} />
        <Route path="/history-deposit-withdrawr" element={<ProtectedRoute element={<HistoryDepositWithdraw />} />} />
        <Route path="/deposit-withdraw" element={<ProtectedRoute element={<DepositWithdraw />} />} />
        <Route path="/detail/:id" element={<ProtectedRoute element={<Detail />} />} />
       <Route path="/historyCapital" element={<ProtectedRoute element={<HistoryCapital />} />} />
       <Route path="/login" element={<ProtectedRoute element={<Login />} />} />
       <Route path="/forgot-password" element={<ForgotPassword />}/>
    </Routes>
  )
}

export default Main;
