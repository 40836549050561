import React, { useState, useEffect} from 'react';
import APILink from '../../service/APILink';
import BoxContent from './BoxContent';

function BoxContentHome() {
    const [listData, setListData] = useState([]);

    useEffect(()=>{
        getListProject();
    },[])
    const getListProject = () => {
        APILink.get('project')
          .then((response) => {
            if (response.data.status === "success") {
                setListData(response.data.result)
            }
          })
          .catch((error) => {
            console.error(error);
          });
      };
    return (
        <div className="w-100">
            <h3 className="text-center fs-4 mt-3 mb-3">Phổ biến</h3>
            <div>
                {listData && listData.length > 0 && listData.map((item)=>{
                    return(
                        <BoxContent item={item} />
                    )
                })}
                
            </div>
        </div>
    );
}

export default BoxContentHome;